import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'

const Twitter = ({
  username,
  title,
  desc,
  image,
  type
}) => (
  <Helmet>
    {username && <meta name='twitter:creator' content={username} />}
    <meta name='twitter:card' content={type} />
    <meta name='twitter:site' content={username} />
    <meta name='twitter:title' content={title} />
    <meta name='twitter:description' content={desc} />
    <meta name='twitter:image' content={image} />
    <meta name='twitter:image:alt' content={desc} />
  </Helmet>
)

export default Twitter